import React from "react";

export const Cz = () => {
  return (
    <>
      <p>Nejčastěji publikuji v těchto časopisech:</p>
    </>
  );
};

export const En = () => {
  return (
    <>
      <p>I publish in these magazines most often:</p>
    </>
  );
};
